import * as React from "react";
import Example from "../components/Example";
import Layout from "../components/Layout";
import FrontHero from "../components/FrontHero";
import Header from "../components/Header";
import SectionLayout from "../components/SectionLayout";
import LogosSection from "../components/LogosSection";
import SecondaryLogosSection from "../components/SecondaryLogosSection";
import ColorUsage from "../components/ColorUsage";
import PhotoBackgroundSection from "../components/PhotoBackgroundSection";
import TypographySection from "../components/TypographySection";
import ColorPalettesSection from "../components/ColorPalettesSection";
import ApplicationsSection from "../components/ApplicationsSection";
// markup
const IndexPage = () => {
  return (
    <Layout>
      <Header></Header>
      <FrontHero></FrontHero>
      <LogosSection></LogosSection>
      <SecondaryLogosSection></SecondaryLogosSection>
      <ColorUsage> </ColorUsage>
      <PhotoBackgroundSection></PhotoBackgroundSection>
      <TypographySection></TypographySection>
      <ColorPalettesSection></ColorPalettesSection>
      <ApplicationsSection></ApplicationsSection>
    </Layout>
  );
};

export default IndexPage;
