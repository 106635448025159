import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import tw, { styled } from "twin.macro";
const Container = styled.div`
  ${tw`relative mx-4 `}
`;
const ATag = styled.a`
  ${tw`absolute bottom-0 w-6 translate-x-12 right-5 md:translate-x-full md:w-8`}
`;
const DownloadableImage = ({ image, file }) => {
  return (
    <Container>
      {image}
      <ATag download href={file}>
        <StaticImage
          placeholder="blurry"
          src="../images/download.png"
          alt="download"
        />
      </ATag>
    </Container>
  );
};

export default DownloadableImage;
