import React from "react";
import tw, { styled } from "twin.macro";
import { StaticImage } from "gatsby-plugin-image";

const Hero = styled.section`
  ${tw`flex flex-col items-center justify-center w-full h-screen `}
  .HeroBackground {
    ${tw`absolute object-cover w-full h-full`}
  }
`;
const FrontHero = () => {
  return (
    <Hero>
      <StaticImage
        className="HeroBackground"
        placeholder="blurred"
        src="../images/background.png"
        alt="background"
      />
      <StaticImage
        placeholder="blurred"
        src="../images/logo-white.png"
        alt="logo white"
      />
    </Hero>
  );
};

export default FrontHero;
