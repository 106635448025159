import React from "react";
import tw, { styled } from "twin.macro";
import { StaticImage } from "gatsby-plugin-image";
import SectionLayout from "./SectionLayout";
import Heading from "./Heading";
import DownloadableImage from "./DownloadableImage";
import logoblack from "../images/logo-black.png";
const Container = styled.div`
  ${tw`flex flex-col items-center justify-center gap-12 pt-20 md:items-start md:flex-row md:pt-36`}
  .min-logo {
    ${tw`min-w-[215px]`}
  }
  .min-size {
    ${tw`hidden sm:block`}
  }
  .paragraph {
    ${tw`py-4 text-black`}
  }
  .download {
    ${tw`absolute right-12 bottom-12`}
  }
  .image {
    ${tw`max-w-2xl min-w-[215px]`}
  }
`;
const LogosSection = () => {
  return (
    <SectionLayout title="Logo">
      <Container>
        <div tw="flex flex-col md:w-1/2">
          <DownloadableImage
            image={
              <StaticImage
                className="image"
                placeholder="blurred"
                src="../images/logo-black.png"
                alt="background"
              />
            }
            file={logoblack}
          ></DownloadableImage>

          <div tw="items-center w-full justify-center gap-6 md:gap-8 flex flex-col-reverse sm:flex-row py-12">
            <StaticImage
              className="min-logo"
              placeholder="blurred"
              src="../images/logo-minimum.png"
              alt="logo minimum"
            />

            <StaticImage
              className="min-size"
              placeholder="blurred"
              src="../images/size-minimum.png"
              alt="logo minimum"
            />
            <div tw="flex flex-col">
              <Heading>Minimum Size</Heading>
              <p tw="text-black">
                The smallest the logo <br />
                should be represented.
              </p>
            </div>
          </div>
        </div>
        <div tw="flex  mx-3 flex-col md:w-1/2 ">
          <Heading>Primary Logo</Heading>
          <p className="paragraph">
            Esquire Digital’s logo uses the condensed “Building” typeface.
            Esquire Digital has purchased the commercial license for this
            typeface.
          </p>
          <p className="paragraph">
            The “Building” typeface is not used anywhere else in web or print
            applications - it is solely for the logo. The word “Digital” is
            using Montserrat, which is the only other font used in association
            with the brand for web or print applications.
          </p>
          <p className="paragraph">
            Only the regular and bold version of Montserrat are used anywhere in
            association with the Esquire Digital brand. Consult with the Art
            Director if you want to pair the font with something outside of
            these brand guidelines.
          </p>
          <p tw="text-primary py-4 font-bold">
            Click here to download the Monteserrat family.
          </p>
        </div>
      </Container>
    </SectionLayout>
  );
};

export default LogosSection;
