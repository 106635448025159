import React from "react";
import { createGlobalStyle } from "styled-components";
import { GlobalStyles } from "twin.macro";

const CustomStyles = createGlobalStyle`
  body {
    font-family: 'Montserrat';
  }
`;

const Layout = ({ children, ...rest }) => (
  <div {...rest}>
    <GlobalStyles />
    <CustomStyles />
    {children}
  </div>
);

export default Layout;
