import React from "react";
import SectionLayout from "./SectionLayout";
import tw, { styled } from "twin.macro";
import Heading from "./Heading";

const Container = styled.div`
  ${tw`flex flex-col w-full pt-12 md:pt-32`}
  .colors {
    ${tw`xl:after:(content[""] absolute right-0 top-0 w-[.1rem] h-full bg-[#c2c2c2])`}
  }
`;
const Circle = styled.div``;
const ColorPalettesSection = () => {
  return (
    <SectionLayout title="Color Palettes">
      <Container>
        <div tw="flex flex-row justify-center md:justify-start ">
          <div tw="flex flex-col gap-12 md:w-1/2">
            <Heading>Color Selection</Heading>
            <p tw="text-black">
              Color is an integral part of brand identity. Consistent use of the
              color palette will reinforce the cohesiveness of the brand.
            </p>
          </div>
        </div>
        <div tw="flex flex-col xl:flex-row  mt-32 gap-8 ">
          <div className="colors" tw="relative xl:w-7/12 pr-8">
            <Heading tw="text-[#b3b3b3] absolute ">
              Primary Color Palette
            </Heading>
            <div tw="flex flex-row flex-wrap justify-center gap-16 xl:gap-8 2xl:gap-16 py-24">
              <div tw="flex flex-col gap-2 items-center">
                <div tw=" rounded-full h-48 w-48 bg-[#020202]"></div>
                <p tw="text-black"> HEX #020202 </p>
              </div>

              <div tw="flex flex-col gap-2 items-center">
                <div tw=" rounded-full h-48 w-48 bg-[#efefef]"></div>
                <p tw="text-black"> HEX #EFEFEF </p>
              </div>
              <div tw="flex flex-col gap-2 items-center">
                <div tw=" rounded-full h-48 w-48 bg-[#238eb8]"></div>
                <p tw="text-black"> HEX #238EB8 </p>
              </div>
            </div>
          </div>
          <div tw="relative xl:w-5/12">
            <Heading tw="text-[#b3b3b3]  absolute">
              Secondary Color Palette
            </Heading>
            <div tw="flex flex-row flex-wrap justify-center gap-12 md:gap-32 xl:gap-12 xl:ml-8 2xl:(gap-16 ml-16) py-24">
              <div tw="flex flex-col gap-2 items-center">
                <div tw=" rounded-full h-48 w-48 bg-[#1e617d]"></div>
                <p tw="text-black"> HEX #1E617D </p>
              </div>
              <div tw="flex flex-col gap-2 items-center">
                <div tw=" rounded-full h-48 w-48 bg-[#182f3b]"></div>
                <p tw="text-black"> HEX #182F3B </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </SectionLayout>
  );
};

export default ColorPalettesSection;
