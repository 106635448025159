import React from "react";
import tw, { styled } from "twin.macro";
import { StaticImage } from "gatsby-plugin-image";
import SectionTitle from "./SectionTitle";

const Container = styled.section`
  ${tw`relative flex flex-col`}
  .AppPic {
    ${tw`object-cover w-full `}
  }
`;
const ApplicationsSection = () => {
  return (
    <Container id="applications">
      <SectionTitle tw="absolute z-50 text-sm top-4 left-2 lg:(text-xl top-20 left-12)">
        Applications
      </SectionTitle>
      <StaticImage
        className="AppPic"
        placeholder="blurred"
        src="../images/app-dark.png"
        alt="cards"
      />
      <StaticImage
        className="AppPic"
        placeholder="blurred"
        src="../images/swag.png"
        alt="cards"
      />
      <StaticImage
        className="AppPic"
        placeholder="blurred"
        src="../images/cards.png"
        alt="cards"
      />
    </Container>
  );
};

export default ApplicationsSection;
