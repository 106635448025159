import React from "react";
import tw from "twin.macro";

const Div = tw.div`text-red-200 bg-black p-8 text-center font-bold`;

const Example = ({ ...rest }) => {
  return <Div {...rest}>Example component</Div>;
};

export default Example;
