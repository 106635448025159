import React from "react";
import SectionLayout from "./SectionLayout";
import tw, { styled } from "twin.macro";
import Heading from "./Heading";
import { StaticImage } from "gatsby-plugin-image";
const Container = styled.div`
  ${tw`flex flex-col gap-8 pt-12 pb-12 md:pt-32`}
  .logo {
    ${tw`max-w-[52rem]`}
    &.smaller {
      ${tw`max-w-[34rem] `}
    }
  }
  .check {
    ${tw`absolute top-0 right-0 z-50 w-8 lg:w-12`}
    transform: translateX(50%) translateY(-50%)
  }
`;
const PhotoBackgroundSection = () => {
  return (
    <SectionLayout title="Photo Background">
      <Container>
        <div tw="flex items-center lg:items-start flex-col-reverse md:flex-row gap-8 lg:gap-24">
          <div tw="flex flex-col sm:flex-row md:flex-col gap-8 md:w-1/2">
            <div tw="relative">
              <StaticImage
                className="logo"
                placeholder="blurred"
                src="../images/logo-bg1.png"
                alt="logo with background 1"
              ></StaticImage>
              <StaticImage
                className="check"
                placeholder="blurred"
                src="../images/checkmark.png"
                alt="checkmark"
              ></StaticImage>
            </div>
            <div tw="relative">
              <StaticImage
                className="logo"
                placeholder="blurred"
                src="../images/logo-bg2.png"
                alt="logo with background 2"
              ></StaticImage>
              <StaticImage
                className="check"
                placeholder="blurred"
                src="../images/checkmark.png"
                alt="checkmark"
              ></StaticImage>
            </div>
          </div>

          <div tw="flex flex-col md:w-1/2 items-center 2xl:px-24 my-8">
            <Heading tw="self-start">Logo on top of images</Heading>
            <p tw="text-black py-8">
              There are a couple of ways the logo can be used on photographic
              backgrounds, but each option should be exercised with care, making
              sure the logo is not obstructed by the image.
            </p>
            <p tw="text-black font-bold self-start">TIPS:</p>
            <p tw="text-black">
              1. Photos with shallow depths-of-field work best 2. Avoid busy
              images with too much detail. 3. Applying a darker transparent
              overlay on an image helps make the logo more readable. 4. Use
              imagery that doesn’t clash with the colors in the logo.
            </p>
          </div>
        </div>
        <div tw="flex flex-col sm:flex-row py-4 gap-6 ">
          <div tw="relative">
            <StaticImage
              className="logo smaller"
              placeholder="blurred"
              src="../images/logo-bg3.png"
              alt="logo with background 3"
            ></StaticImage>
            <StaticImage
              className="check"
              placeholder="blurred"
              src="../images/x.png"
              alt="x"
            ></StaticImage>
          </div>
          <div tw="relative">
            <StaticImage
              className="logo smaller"
              placeholder="blurred"
              src="../images/logo-bg4.png"
              alt="logo with background 4"
            ></StaticImage>
            <StaticImage
              className="check"
              placeholder="blurred"
              src="../images/x.png"
              alt="x"
            ></StaticImage>
          </div>
          <div tw="relative">
            <StaticImage
              className="logo smaller"
              placeholder="blurred"
              src="../images/logo-bg5.png"
              alt="logo with background 5"
            ></StaticImage>
            <StaticImage
              className="check"
              placeholder="blurred"
              src="../images/x.png"
              alt="x"
            ></StaticImage>
          </div>
        </div>
      </Container>
    </SectionLayout>
  );
};

export default PhotoBackgroundSection;
