import React from "react";
import tw, { styled } from "twin.macro";
import SectionTitle from "./SectionTitle";

const MenuItem = styled.li`
  ${tw``}
`;
const HeaderSection = styled.header`
  ${tw`hidden lg:(z-50 flex flex-row justify-between py-12 w-full absolute px-12 bg-transparent text-[#b3b3b3] text-base font-bold tracking-widest font-mont)`}
`;
const Header = () => {
  return (
    <HeaderSection>
      <SectionTitle>Brand Guidelines</SectionTitle>
      <ul tw="flex flex-row gap-6">
        <MenuItem>Brand Guidelines</MenuItem>
        <MenuItem>Asset Library</MenuItem>
      </ul>
    </HeaderSection>
  );
};

export default Header;
