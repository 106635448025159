import React from "react";
import SectionLayout from "./SectionLayout";
import tw, { styled } from "twin.macro";
import Heading from "./Heading";
import { StaticImage } from "gatsby-plugin-image";
const Container = styled.div`
  ${tw`flex flex-col items-center gap-8 sm:flex-row lg:gap-12 xl:gap-20`}
  .main {
    ${tw`max-w-[40rem]`}
  }
`;
const PTag = styled.p`
  ${tw`absolute text-sm font-bold top-6 left-4 lg:top-8 lg:left-8 md:text-base text-primary`}
`;
const LogoExample = styled.div`
  ${tw`relative flex flex-row justify-center gap-4 px-6 py-12 lg:gap-12 lg:px-16 lg:py-20 `}
  .logo {
    ${tw`max-w-[23rem]`}
    &.bigger {
      ${tw`max-w-[28rem]`}
    }
  }
`;
const ColorUsage = () => {
  return (
    <SectionLayout title="Color Usage">
      <Container>
        <div tw="flex flex-col sm:w-1/2 px-4 xl:px-12 py-12 sm:py-32">
          <Heading>Logo color usage</Heading>
          <p tw="py-12 text-black">
            Esquire Digital’s color usage is quite minimal. The border box has
            to be #228eb8 on both a dark and light background. The blue border
            should not clash with any background color, stick with greyscale or
            darker blues in that hue. If a one color logo is needed, use “b.” or
            “c.”
          </p>
          <p tw="text-primary font-bold py-5">Primary Logo Light Background</p>
          <StaticImage
            className="main"
            placeholder="blurred"
            src="../images/logo-black.png"
            alt="logo black"
          ></StaticImage>
        </div>
        <div tw="flex flex-col sm:w-1/2 relative xl:px-12 2xl:px-24">
          <LogoExample tw="bg-black pt-16 lg:pt-24 pb-8">
            <PTag>
              a. <span tw="pl-2 2xl:pl-10">Primary Logo Dark Background</span>
            </PTag>
            <StaticImage
              className="logo"
              placeholder="blurred"
              src="../images/esquire-a.png"
              alt="logo a"
            ></StaticImage>
          </LogoExample>

          <LogoExample tw="bg-black px-12">
            <PTag>b.</PTag>
            <StaticImage
              className="logo"
              placeholder="blurred"
              src="../images/esquire-b.png"
              alt="logo b"
            ></StaticImage>
          </LogoExample>
          <LogoExample tw="bg-white shadow-lg px-12">
            <PTag>c.</PTag>
            <StaticImage
              className="logo"
              placeholder="blurred"
              src="../images/esquire-c.png"
              alt="logo c"
            ></StaticImage>
          </LogoExample>
        </div>
      </Container>
    </SectionLayout>
  );
};

export default ColorUsage;
