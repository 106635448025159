import React, { Children } from "react";
import tw, { styled } from "twin.macro";
import SectionTitle from "./SectionTitle";
const Container = styled.section`
  ${tw` px-4 lg:px-16 relative flex flex-col pt-12 bg-[#f6f6f6] w-full text-[#c0c0c0]`}
  hr {
    ${tw``}
  }
`;
const SectionLayout = ({ title, children }) => {
  return (
    <Container>
      <SectionTitle tw=" top-10 left-6 md:top-20 md:left-12 absolute">
        {title}
      </SectionTitle>
      {children}
      <hr tw="mt-12"></hr>
    </Container>
  );
};

export default SectionLayout;
