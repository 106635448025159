import React from "react";
import SectionLayout from "./SectionLayout";
import tw, { styled } from "twin.macro";
import Heading from "./Heading";
import { StaticImage } from "gatsby-plugin-image";
import AnchorLink from "react-anchor-link-smooth-scroll";
import DownloadableImage from "./DownloadableImage";
import logoa from "../images/esquire-a.png";
import logob from "../images/esquire-b.png";
import logoc from "../images/esquire-c.png";
import logoround from "../images/esquire-round.png";
const Container = styled.div`
  ${tw`flex flex-col gap-12 sm:flex-row md:gap-24 `}
`;
const LogoExample = styled.div`
  ${tw`relative flex flex-row items-center justify-center gap-12 px-12 py-12 `}
  .logo {
    ${tw` max-w-[23rem]`}
    &.bigger {
      ${tw`max-w-[28rem]`}
    }
    &.biggest {
      ${tw`max-w-[38rem]`}
    }
  }
`;
const SecondaryLogosSection = () => {
  return (
    <SectionLayout title="Secondary Logos">
      <Container>
        <div tw="sm:w-1/2 px-6 pt-16 sm:py-24 lg:px-12 lg:py-32">
          <Heading>Secondary Logos</Heading>
          <p tw="py-8 text-black">
            Esquire Digital’s secondary logos can be used in replace of the
            primary logo. The secondary logos can be placed by the primary logo.
            See examples in the{" "}
            <AnchorLink href="#applications" tw="text-primary font-bold">
              application
            </AnchorLink>{" "}
            of the logos.
          </p>
        </div>
        <div tw="sm:w-1/2 flex flex-col xl:px-24">
          <LogoExample tw="bg-black ">
            <p tw=" absolute top-8 left-6 font-bold text-primary">a.</p>
            <DownloadableImage
              image={
                <StaticImage
                  className="logo bigger"
                  placeholder="blurred"
                  src="../images/esquire-a.png"
                  alt="logo a"
                />
              }
              file={logoa}
            ></DownloadableImage>
          </LogoExample>
          <LogoExample tw="bg-black">
            <p tw="absolute top-8 left-6 font-bold text-primary">b.</p>
            <DownloadableImage
              image={
                <StaticImage
                  className="logo"
                  placeholder="blurred"
                  src="../images/esquire-b.png"
                  alt="logo b"
                />
              }
              file={logob}
            ></DownloadableImage>
          </LogoExample>
          <LogoExample tw="bg-white shadow-lg">
            <p tw=" absolute top-8 left-6 font-bold text-primary">c.</p>
            <DownloadableImage
              image={
                <StaticImage
                  className="logo smaller"
                  placeholder="blurred"
                  src="../images/esquire-c.png"
                  alt="logo c"
                />
              }
              file={logoc}
            ></DownloadableImage>
          </LogoExample>
          <LogoExample tw="bg-transparent">
            <p tw="absolute font-bold top-8 left-6 text-primary">d.</p>
            <DownloadableImage
              image={
                <StaticImage
                  className="logo biggest"
                  placeholder="blurred"
                  src="../images/esquire-round.png"
                  alt="logo round"
                />
              }
              file={logoround}
            ></DownloadableImage>
          </LogoExample>
        </div>
      </Container>
    </SectionLayout>
  );
};
export default SecondaryLogosSection;
