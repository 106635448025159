import React from "react";
import SectionLayout from "./SectionLayout";
import tw, { styled } from "twin.macro";
import Heading from "./Heading";
import { StaticImage } from "gatsby-plugin-image";
const Container = styled.div`
  ${tw`flex flex-col items-center gap-16 pt-8 md:pt-16`}
  .download {
    ${tw`absolute w-4 sm:w-6 sm:translate-x-4 right-4 bottom-12 sm:right-0`}
  }
`;
const TypeDescription = styled.div`
  ${tw`flex flex-col gap-2 text-black before:(content[""] h-[0.1rem] w-full  bg-black)`}
`;
const TypographySection = () => {
  const charString = "!@#$%^&*()?><”:|}{/.,’;][";
  return (
    <SectionLayout title="Typography">
      <Container>
        <div tw="flex flex-row py-12 md:py-16">
          <div tw="sm:w-1/2">
            <Heading>Typography</Heading>
            <p tw="text-black">
              Typography is a powerful brand tool when used consistently. This
              font best represents the sleek feel Esquire Digital is
              representing and should be used across all web and print
              applications.
            </p>
          </div>
        </div>
        <div tw="flex flex-col relative items-center gap-6">
          <big tw="text-2xl sm:text-4xl md:text-6xl xl:text-7xl  tracking-widest md:letter-spacing[1rem] text-black font-bold uppercase ">
            Montserrat
          </big>
          <p tw="text-black text-base sm:text-lg md:text-xl">
            Regular + <span tw="font-bold">Bold +</span>
            <span tw="font-bold tracking-widest"> Letterspacing</span>
          </p>
          <StaticImage
            className="download"
            placeholder="blurred"
            src="../images/download.png"
            alt="download"
          />
        </div>
        <div tw="flex flex-col items-center lg:items-start lg:flex-row gap-24 xl:gap-64 text-base mt-16">
          <div tw="flex flex-col items-center w-2/5 ">
            <TypeDescription>
              <p tw="font-bold py-4">Montserrat</p>
              <p tw="">All Montserrat, All Day.</p>
              <big tw="font-bold leading-snug text-[12rem] sm:text-[16rem]">
                Ag
              </big>
            </TypeDescription>
          </div>
          <div tw="flex flex-col w-3/5 gap-24 items-center">
            <TypeDescription>
              <div tw="flex flex-col sm:flex-row gap-8 sm:gap-16 lg:gap-20 xl:gap-28 py-4 pr-4 justify-center">
                <p>Regular</p>
                <div>
                  <p>ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
                  <p>abcdefghijklmnopqrstuvwxyz</p>
                  <p>1234567890</p>

                  <p>{charString}</p>
                </div>
              </div>
            </TypeDescription>
            <TypeDescription tw="font-bold">
              <div tw="flex flex-col sm:flex-row gap-8 sm:gap-16 lg:gap-20 xl:gap-28 py-4 pr-4 justify-center">
                <p>Bold</p>
                <div>
                  <p>ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
                  <p>abcdefghijklmnopqrstuvwxyz</p>
                  <p>1234567890</p>

                  <p>{charString}</p>
                </div>
              </div>
            </TypeDescription>
          </div>
        </div>
      </Container>
    </SectionLayout>
  );
};

export default TypographySection;
